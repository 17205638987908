import React, { useEffect, useRef, useState } from "react";
import { BrowserView, isMobileOnly, MobileView } from "react-device-detect";

// components
import { styled } from "@material-ui/core";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { ContactButton, ModalMakeOrderButton, TransButton } from "../_shared";
import { ContentActions } from "../../state/content";
import { useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Brightness1 } from "@mui/icons-material";

export default (_props: any) => {
    const theme = createTheme();
    const dispatch = useDispatch();

    const isMobileOnly = window.outerWidth < 500;

    const video = "https://tctrading.s3.amazonaws.com/utils/horsevid.mp4";

    const ExpandIcon = () => (
        <>
            <svg width="33" height="16" viewBox="0 0 33 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 1.50977L16.5 14.5098L32 1.50977"
                    stroke="#898989"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );

    const [isScrolled, setIsScrolled] = useState(false);
    const [secondScrolled, setSecondScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
            setSecondScrolled(window.scrollY > window.innerHeight)
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <div className="element-o">
                <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    id="video"
                    className={isMobileOnly ? "mobile-horsepic" : "horsepic"}
                    style={{
                        objectPosition: isMobileOnly ? "60% 50%" : "50% 50%"
                    }}
                >
                    <source src={video} type={"video/mp4"} />
                </video>
                {isMobileOnly && 
                <img
                    className={isMobileOnly ? "mobilelogo" : "logo"}
                    alt="Logo"
                    src="https://tctrading.s3.us-east-1.amazonaws.com/whitehorse.png"
                    onClick={() => dispatch(ContentActions.setContent("home"))}
                    style={{ cursor: "pointer" }}
                />}
                {!isMobileOnly && (
                    <div
                        style={{
                            height: '70px',
                            width: '100vw',
                            position: 'fixed',
                            top: 0,
                            zIndex: 20,
                            transition: 'background 0.5s, borderBottom 0.5s',
                            background: isScrolled ? '#202409' : 'transparent',
                            borderBottom: secondScrolled ? 'solid #AEAEAE 1px' : 'none',
                        }}
                    >
                        <img
                            className={isMobileOnly ? "mobilelogo" : "logo"}
                            alt="Logo"
                            src="https://tctrading.s3.us-east-1.amazonaws.com/whitehorse.png"
                            onClick={() => dispatch(ContentActions.setContent("home"))}
                            style={{ cursor: "pointer" }}
                        />
                        <div
                            className="text-wrapper"
                            onClick={() => dispatch(ContentActions.setContent("home"))}
                            style={{ cursor: "pointer" }}
                        >
                            TRIPLE CROWN TRADING
                        </div>
                        <div className="investnowtop" style={{top: '15px'}}>
                            <ModalMakeOrderButton
                                style={{
                                    position: "fixed",
                                    right: "40px",
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "white",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    height: "40px",
                                    background: isScrolled? "#925F22" : "#202409",
                                    transition: '0.5s',
                                    cursor: "pointer",
                                    zIndex: 20
                                }}
                                variant="contained"
                                onClick={() =>
                                    (window.location.href =
                                        "https://triplecrowntrading.deal.tribexa.com/deal/create-investment")
                                }
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = isScrolled? "#7A4F33" : "black";
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                    target.style.backgroundColor = isScrolled ? "#925F22" : "#202409";
                                }}
                            >
                                Invest now
                            </ModalMakeOrderButton>
                        </div>
                        <div className="contactustop" style={{top: '15px'}}>
                            <ContactButton
                                style={{
                                    alignSelf: "center",
                                    width: "192px",
                                    fontSize: "16px",
                                    color: "white",
                                    fontFamily: "Libre Caslon Text",
                                    textTransform: "none",
                                    // fontStyle: "italic",
                                    borderRadius: "25px",
                                    boxShadow: "none",
                                    fontWeight: 30,
                                    gap: "8px",
                                    padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                    height: "40px"
                                }}
                                variant="text"
                                onClick={() => dispatch(ContentActions.setContent("Contact us"))}
                                onMouseEnter={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                                onMouseLeave={(event) => {
                                    const target = event.target as HTMLElement;
                                }}
                            >
                                Contact us
                            </ContactButton>
                        </div>
                    </div>
                )}
                <div className={isMobileOnly ? "mobile-landing-2" : "landing-2"}>
                    <h1 className="h-1" style={{fontSize: isMobileOnly ? "60px" : "72px"}}>Experience Real Ownership.</h1>
                    <p className={isMobileOnly ? "mobile-p" : "p"} style={{width: isMobileOnly ? "85vw" : "500px"}}>
                        Purchase shares of thoroughbred racehorses through Triple Crown Trading.
                    </p>
                    <div className="join-the-waitlist">
                        <ModalMakeOrderButton
                            style={{
                                alignSelf: "center",
                                width: "252px",
                                fontSize: "20px",
                                color: "white",
                                fontFamily: "Libre Caslon Text",
                                textTransform: "none",
                                fontStyle: "italic",
                                borderRadius: "25px",
                                boxShadow: "none",
                                // fontWeight: 500,
                                gap: "8px",
                                height: "50px",
                                marginTop: "20px",
                                background: "#925F22"
                            }}
                            variant="contained"
                            onClick={() => dispatch(ContentActions.setContent("offering"))}
                            onMouseEnter={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "#7A4F33";
                            }}
                            onMouseLeave={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.backgroundColor = "#925F22";
                            }}
                        >
                            View offering
                        </ModalMakeOrderButton>
                    </div>
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "950px" : "700px",
                        // display: 'flex',
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div
                        style={{
                            fontFamily: "Libre Caslon Text",
                            fontSize: "20px",
                            textAlign: "center",
                            fontStyle: "italic"
                        }}
                    >
                        Our mission
                    </div>
                    <div
                        style={{
                            fontFamily: "Libre Caslon Text",
                            fontSize: isMobileOnly ? "45px" : "60px",
                            lineHeight: '60px',
                            textAlign: "center",
                            marginTop: isMobileOnly ? "0px" : "30px",
                            paddingLeft: isMobileOnly ? "10px" : 0,
                            paddingRight: isMobileOnly ? "10px" : 0,
                        }}
                    >
                        Real ownership, starting at $2,500.
                    </div>
                    <div
                        style={{
                            marginTop: isMobileOnly ? "50px" : "100px",
                            display: "flex",
                            flexDirection: isMobileOnly ? "column" : "row",
                            alignContent: "center",
                            justifyContent: "space-evenly",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: isMobileOnly ? "80%" : "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Text",
                                    fontStyle: "italic",
                                    fontSize: "40px"
                                }}
                            >
                                Buy
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    lineHeight: '27px'
                                }}
                            >
                                Pick from the best upcoming thoroughbreds, exclusive to our platform.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: isMobileOnly ? "80%" : "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Text",
                                    fontStyle: "italic",
                                    fontSize: "40px"
                                }}
                            >
                                Profit
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    lineHeight: '27px'
                                }}
                            >
                                Watch your horses succeed on the racetrack and collect your share of their on and off
                                track earnings.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: isMobileOnly ? "80%" : "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Text",
                                    fontStyle: "italic",
                                    fontSize: "40px"
                                }}
                            >
                                Enjoy
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    lineHeight: '27px'
                                }}
                            >
                                Get access to exclusive ownership opportunities throughout the duration of the
                                partnership.
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        height: isMobileOnly ? "1000px" : "500px"
                    }}
                >
                    <div
                        style={{
                            width: isMobileOnly ? "100%" : "50%",
                            height: isMobileOnly ? "500px" : "auto",
                            backgroundImage: `url(https://tctrading.s3.us-east-1.amazonaws.com/photos/horse1.jpeg)`,
                            backgroundSize: "cover",
                            backgroundPosition: "10% 70%",
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            alt="Lane s end"
                            src="https://tctrading.s3.us-east-1.amazonaws.com/utils/lanesendlight.png"
                            style={{
                                width: isMobileOnly ? "300px" : "400px"
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: isMobileOnly ? "100%" : "50%",
                            height: isMobileOnly ? "500px" : "auto",
                            background: "#202409",
                            display: "flex",
                            flexDirection: "column",

                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "45px" : "50px",
                                color: "white",
                                marginTop: isMobileOnly ? "70px" : "150px",
                                marginLeft: isMobileOnly ? "40px" : "60px"
                            }}
                        >
                            Top Thoroughbreds
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                color: "white",
                                marginLeft: isMobileOnly ? "40px" : "60px",
                                marginTop: "30px",
                                marginRight: isMobileOnly ? "40px" : "70px",
                                lineHeight: '27px'
                            }}
                        >
                            Our partnership with Lane’s End, one of the most prestigious stallion operations and breeding farms in the Thoroughbred world, enables us to access top prospects. Lane’s End has been home to winners like A.P. Indy, Zenyatta, Flightline, and Honor Code.
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row-reverse",
                        height: isMobileOnly ? "1000px" : "500px"
                    }}
                >
                    <div
                        style={{
                            width: isMobileOnly ? "100%" : "50%",
                            height: isMobileOnly ? "500px" : "auto",
                            backgroundImage: `url(https://tctrading.s3.us-east-1.amazonaws.com/photos/celebration.jpeg)`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                backdropFilter: "brightness(40%)",
                                height: "100%"
                            }}
                        >
                            <img
                                alt="Lane s end"
                                src="https://tctrading.s3.us-east-1.amazonaws.com/utils/WOODFORD+RACING+LLC.png"
                                style={{
                                    width: isMobileOnly ? "300px" : "400px"
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            width: isMobileOnly ? "100%" : "50%",
                            height: isMobileOnly ? "500px" : "auto",
                            background: "#202409",
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "45px" : "50px",
                                color: "white",
                                marginTop: isMobileOnly ? "70px" : "150px",
                                marginLeft: isMobileOnly ? "40px" : "60px"
                            }}
                        >
                            Exclusive Experiences
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                color: "white",
                                marginLeft: isMobileOnly ? "40px" : "60px",
                                marginTop: "30px",
                                marginRight: isMobileOnly ? "40px" : "70px",
                                lineHeight: '27px'
                            }}
                        >
                            Our relationship with Woodford Racing, a high-end partnership business, allows us to facilitate top of the line experiences for our investors as they’ve been doing so successfully for nearly two decades. Woodford is home to Flightline, Honor Code, Catapult, and Engage. 
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "900px"  : "400px",
                        // display: 'flex',
                        flexDirection: "column",
                        alignContent: "center",
                        justifyContent: "space-evenly"
                    }}
                >
                    <div
                        style={{
                            fontFamily: "Libre Caslon Text",
                            fontSize: "20px",
                            textAlign: "center",
                            fontStyle: "italic"
                        }}
                    >
                        Our stat line
                    </div>
                    <div
                        style={{
                            marginTop: "50px",
                            height: isMobileOnly ? "700px" : "auto",
                            display: "flex",
                            flexDirection: isMobileOnly ? "column" : "row",
                            alignContent: "center",
                            justifyContent: isMobileOnly ? "space-between" : "space-evenly",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Display",
                                    textAlign: "center",
                                    fontSize: "120px"
                                }}
                            >
                                20%
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    textAlign: "center"
                                }}
                            >
                                more equity per dollar than similar opportunities.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Display",
                                    textAlign: "center",
                                    fontSize: "120px"
                                }}
                            >
                                90%
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    textAlign: "center"
                                }}
                            >
                                of profits are returned to investors.
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "200px",
                                width: "300px",
                                textAlign: "left"
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "30px",
                                    fontFamily: "Libre Caslon Display",
                                    textAlign: "center",
                                    fontSize: "120px"
                                }}
                            >
                                100%
                            </div>
                            <div
                                style={{
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "24px",
                                    textAlign: "center"
                                }}
                            >
                                of excess training reserve returned to investors.
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "150px" : "100px",
                        background: "#F2F2F2",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        gap: isMobileOnly ? "20px" : '40px'
                    }}
                >
                    <div style={{ fontFamily: "Abhaya Libre", fontSize: "20px" }}>
                            Limited time offering available now.
                        </div>
                        <TransButton
                            style={{
                                alignSelf: "center",
                                width: "181px",
                                fontSize: "16px",
                                color: "black",
                                fontFamily: "Libre Caslon Text",
                                textTransform: "none",
                                borderRadius: "25px",
                                boxShadow: "none",
                                fontWeight: 30,
                                gap: "8px",
                                padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                height: "30px"
                            }}
                            variant="outlined"
                            onClick={() => dispatch(ContentActions.setContent("offering"))}
                            onMouseEnter={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.borderColor = "#202409";
                            }}
                            onMouseLeave={(event) => {
                                const target = event.target as HTMLElement;
                                target.style.borderColor = "grey.500";
                            }}
                            sx={{ borderColor: "grey.500" }}
                        >
                            View offering
                        </TransButton>
                    {/* <div
                        style={{
                            fontFamily: "Abhaya Libre",
                            fontSize: "20px"
                        }}
                    >
                        Featured on...
                    </div> */}
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "auto" : "600px",
                        display: "flex",
                        marginTop: '50px',
                        flexDirection: isMobileOnly ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "space-between",
                        alignItems: "bottom",
                        padding: isMobileOnly ? "30px 30px" : "70px 60px",
                        marginBottom: isMobileOnly ? "40px" : 0
                    }}
                >
                    <img
                        alt="horse"
                        src="https://tctrading.s3.us-east-1.amazonaws.com/photos/jockey1.jpeg"
                        style={{
                            maxHeight: "450px",
                            marginLeft: isMobileOnly ? 0 : '40px',
                            marginBottom: "isMobileOnly" ? "15px" : 0
                        }}
                    />
                    <div
                        style={{
                            height: "100%",
                            width: isMobileOnly ? "auto" : '50vw',
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            padding: isMobileOnly ? "10px 0px" : "30px 70px"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "60px",
                                marginBottom: "30px"
                            }}
                        >
                            Our fees are simple.
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                lineHeight: '27px'
                            }}
                        >
                            No hidden fees, no subscription costs. All future costs are included in the offering. We take a 10% fee on the asset cost to facilitate the offering, and Woodford takes 5% for ownership experiences. Additionally, we retain 10% of the horse’s profits.
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "auto" : "600px",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row-reverse",
                        alignContent: "center",
                        justifyContent: "space-evenly",
                        alignItems: "bottom",
                        padding: isMobileOnly ? "10px 30px" : "70px 60px",
                        marginBottom: isMobileOnly ? "40px" : 0
                    }}
                >
                    <img
                        alt="horse"
                        src="https://tctrading.s3.us-east-1.amazonaws.com/photos/jockeycelebration.jpeg"
                        style={{
                            height: isMobileOnly ? "auto " : "450px",
                            marginRight: isMobileOnly ? 0 : '40px',
                            marginBottom: "isMobileOnly" ? "15px" : 0
                        }}
                    />
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            padding: isMobileOnly ? "10px 0px" : "30px 70px"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "60px",
                                marginBottom: "30px",
                                marginLeft: isMobileOnly ? 0 : '-20px'
                            }}
                        >
                            Your money is safe with us.
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                marginLeft: isMobileOnly ? 0 : '-20px',
                                lineHeight: '27px'
                            }}
                        >
                           We operate under Regulation D, Rule 506(c), ensuring compliance with industry standards. The training reserve is securely held in an escrow account for the duration of the partnership. Additionally, we’ve partnered with Industry FinTech Inc. to facilitate secure transactions on our platform.
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        height: isMobileOnly ? "auto" : "600px",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        alignContent: "center",
                        justifyContent: "space-evenly",
                        alignItems: "bottom",
                        padding: isMobileOnly ? "10px 30px" : "70px 60px",
                        // marginBottom: '50px'
                    }}
                >
                    <img
                        alt="horse"
                        src="https://tctrading.s3.us-east-1.amazonaws.com/photos/celebration2.jpeg"
                        style={{
                            height: isMobileOnly ? "auto " : "450px",
                            marginLeft: isMobileOnly ? 0 : '40px',
                            marginBottom: "isMobileOnly" ? "15px" : 0
                        }}
                    />
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                            alignContent: "center",
                            padding: isMobileOnly ? "10px 0px" : "30px 70px"
                        }}
                    >
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "60px",
                                marginBottom: "30px"
                            }}
                        >
                            Enjoy ownership.
                        </div>
                        <div
                            style={{
                                fontFamily: "Abhaya Libre",
                                fontSize: "24px",
                                lineHeight: '27px'
                            }}
                        >
                            Through our partnership with Woodford, we aim to offer investors exclusive access to premier racing experiences. These include tickets to watch their horses race, VIP access to the Breeders' Cup and other major races, tickets to the Keeneland sale, and guided tours of the farm.
                        </div>
                    </div>
                </div>
                {/* <div
                    style={{
                        height: isMobileOnly ? '800px' : "700px",
                        background: "#925F22",
                        display: "flex",
                        flexDirection: isMobileOnly ? "column" : "row",
                        justifyContent: isMobileOnly ? "space-evenly" : "center",
                        alignContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div
                        style={{
                            fontFamily: "Libre Caslon Text",
                            fontSize: "45px",
                            fontStyle: "italic",
                            textAlign: "center",
                            color: "white",
                            marginRight: isMobileOnly ? 0 : "40px",
                            maxWidth: isMobileOnly ? "80%" : "60%"
                        }}
                    >
                        “I've never seen a team more dedicated to bringing real ownership to everyone.”
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <img
                            src={"https://tctrading.s3.amazonaws.com/profiles/bill.png"}
                            style={{
                                width: isMobileOnly ? "70vw" : "250px",
                                borderRadius: isMobileOnly ? "35vw" : "175px"
                            }}
                        />
                        <div
                            style={{
                                fontFamily: "Libre Caslon Text",
                                fontSize: "20px",
                                color: "white",
                                marginTop: "30px"
                            }}
                        >
                            Bill Farish
                        </div>
                        <div
                            style={{
                                fontFamily: "IBM Plex Mono",
                                fontSize: "20px",
                                color: "white",
                                marginTop: "10px"
                            }}
                        >
                            CEO OF LANE'S END
                        </div>
                    </div>
                </div> */}
                <div
                    style={{
                        width: "100%",
                        marginTop: isMobileOnly ? "00px" : "50px",
                        padding: isMobileOnly ? "100px 30px" : "0px 115px"
                    }}
                >
                    <div
                        style={{
                            color: "black",
                            fontFamily: "Libre Caslon Text",
                            fontSize: "60px",
                            fontWeight: "400px",
                            marginBottom: "70px"
                        }}
                    >
                        Frequently Asked Questions
                    </div>
                    <Accordion
                        defaultExpanded
                        sx={{
                            boxShadow: "none", // Remove box shadow
                            border: "none", // Ensure no border around the Accordion
                            "&:before": {
                                // Remove the default before pseudo-element that creates a border-like effect
                                display: "none"
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                border: "none",
                                borderTop: "none", // Remove the top border
                                padding: 0 // Optional: Remove padding for a cleaner look
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "32px",
                                    fontWeight: 400,
                                    width: "80%"
                                }}
                            >
                                How can I invest?
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px" }}>
                                To invest, click the "Invest now" button to enter our investor portal, where you can
                                verify your accredited investor status and complete your investment.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            padding: "0 10%",
                            background: "#D9D9D9",
                            marginTop: "30px",
                            marginBottom: "30px"
                        }}
                    />
                    <Accordion
                        sx={{
                            boxShadow: "none", // Remove box shadow
                            border: "none", // Ensure no border around the Accordion
                            "&:before": {
                                // Remove the default before pseudo-element that creates a border-like effect
                                display: "none"
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                border: "none",
                                borderTop: "none", // Remove the top border
                                padding: 0 // Optional: Remove padding for a cleaner look
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "32px",
                                    fontWeight: 400,
                                    width: "80%"
                                }}
                            >
                                Who can buy shares?
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px" }}>
                                This offering is only available to accredited investors. You qualify as an accredited
                                investor if you meet one of the following criteria:
                                <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                                    <text style={{ fontWeight: "bold" }}>Income: </text> Earned over $200,000 in each of
                                    the last two years (or $300,000 with a spouse) and expect the same this year.
                                </div>
                                <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                                    <text style={{ fontWeight: "bold" }}>Net Worth: </text> Have a net worth exceeding
                                    $1 million, excluding the value of your primary residence.
                                </div>
                                <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                                    <text style={{ fontWeight: "bold" }}>Professional Qualification: </text> Hold a
                                    Series 7, 65, or 82 license.
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            padding: "0 10%",
                            background: "#D9D9D9",
                            marginTop: "30px",
                            marginBottom: "30px"
                        }}
                    />
                    <Accordion
                        sx={{
                            boxShadow: "none", // Remove box shadow
                            border: "none", // Ensure no border around the Accordion
                            "&:before": {
                                // Remove the default before pseudo-element that creates a border-like effect
                                display: "none"
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                border: "none",
                                borderTop: "none", // Remove the top border
                                padding: 0 // Optional: Remove padding for a cleaner look
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "32px",
                                    fontWeight: 400,
                                    width: "80%"
                                }}
                            >
                                How many shares can I buy?
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px" }}>
                                You may purchase up to 50 shares, equivalent to a $25,000 investment. This limit is
                                subject to change.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            padding: "0 10%",
                            background: "#D9D9D9",
                            marginTop: "30px",
                            marginBottom: "30px"
                        }}
                    />
                    <Accordion
                        sx={{
                            boxShadow: "none", // Remove box shadow
                            border: "none", // Ensure no border around the Accordion
                            "&:before": {
                                // Remove the default before pseudo-element that creates a border-like effect
                                display: "none"
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                border: "none",
                                borderTop: "none", // Remove the top border
                                padding: 0 // Optional: Remove padding for a cleaner look
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "32px",
                                    fontWeight: 400,
                                    width: "80%"
                                }}
                            >
                                When and how will I receive dividends?
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px" }}>
                                Dividends will be issued following revenue-generating events. We strive to distribute
                                dividends consistently and will ensure all payouts are made by the end of the
                                partnership at the latest.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            padding: "0 10%",
                            background: "#D9D9D9",
                            marginTop: "30px",
                            marginBottom: "30px"
                        }}
                    />
                    <Accordion
                        sx={{
                            boxShadow: "none", // Remove box shadow
                            border: "none", // Ensure no border around the Accordion
                            "&:before": {
                                // Remove the default before pseudo-element that creates a border-like effect
                                display: "none"
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{
                                border: "none",
                                borderTop: "none", // Remove the top border
                                padding: 0 // Optional: Remove padding for a cleaner look
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: "Libre Caslon Text",
                                    fontSize: "32px",
                                    fontWeight: 400,
                                    width: "80%"
                                }}
                            >
                                How long is the partnership?
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div style={{ fontFamily: "Abhaya Libre", fontSize: "24px" }}>
                                The partnership is set to conclude on December 31, 2027, though this date may be
                                adjusted based on circumstances.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <div
                        style={{
                            height: "1px",
                            width: "100%",
                            padding: "0 10%",
                            background: "#D9D9D9",
                            marginTop: "30px",
                            marginBottom: "30px"
                        }}
                    />
                </div>
                <div style={{ height: isMobileOnly ? "700px" : "500px", background: "#202409" }}>
                    <div
                        style={{
                            width: "100%",
                            padding: isMobileOnly ? "100px 30px" : "100px 120px",
                            display: "flex",
                            flexDirection: isMobileOnly ? "column" : "row",
                            alignContent: "center",
                            justifyContent: "space-evenly",
                        }}
                    >
                        <div
                            style={{
                                color: "white",
                                fontFamily: "Libre Caslon Text",
                                fontSize: isMobileOnly ? "46px" : "72px",
                                fontWeight: 500,
                                width: isMobileOnly ? "100%" : "500px",
                                lineHeight: isMobileOnly ? "auto" : "80px",
                                textAlign: "left",
                                // paddingRight: "30px",
                                // marginLeft: isMobileOnly ? 0 : "-50px"
                            }}
                        >
                            Join our partnership today.
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between' }}>
                            <p
                                style={{
                                    color: "white",
                                    fontFamily: "Abhaya Libre",
                                    fontSize: "22px",
                                    fontWeight: 400,
                                    width: isMobileOnly ? "100%" : "550px",
                                    lineHeight: "normal",
                                    textAlign: "left",
                                    marginBottom: "50px",
                                    marginTop: isMobileOnly ? "50px" : 0
                                }}
                            >
                                Get started today. Limited offering available until December. We’re here to help, every step of the way. 
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: isMobileOnly ? "column" : "row",
                                    alignContent: "center",
                                    justifyContent: "space-between",
                                    width: isMobileOnly ? "100%" : "90%"
                                }}
                            >
                                <ModalMakeOrderButton
                                    style={{
                                        alignSelf: "center",
                                        width: isMobileOnly ? "100%" : "216px",
                                        fontSize: "20px",
                                        color: "black",
                                        fontFamily: "Libre Caslon Text",
                                        textTransform: "none",
                                        fontStyle: "italic",
                                        borderRadius: "25px",
                                        boxShadow: "none",
                                        // fontWeight: 500,
                                        gap: "8px",
                                        height: "50px",
                                        background: "white"
                                    }}
                                    variant="contained"
                                    onClick={() => dispatch(ContentActions.setContent("offering"))}
                                    onMouseEnter={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "#AEAEAE";
                                    }}
                                    onMouseLeave={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.backgroundColor = "white";
                                    }}
                                >
                                    View offering
                                </ModalMakeOrderButton>
                                <ModalMakeOrderButton
                                    style={{
                                        alignSelf: "center",
                                        width: isMobileOnly ? "100%" : "216px",
                                        fontSize: "20px",
                                        color: "white",
                                        fontFamily: "Libre Caslon Text",
                                        textTransform: "none",
                                        fontStyle: "italic",
                                        marginTop: isMobileOnly ? "20px" : "0",
                                        borderRadius: "25px",
                                        boxShadow: "none",
                                        fontWeight: 500,
                                        gap: "8px",
                                        padding: "var(--space-xxxs, 4px) var(--space-s, 23.1px)",
                                        height: "50px"
                                    }}
                                    variant="outlined"
                                    onClick={() => dispatch(ContentActions.setContent("Contact us"))}
                                    sx={{ borderColor: "white" }}
                                    onMouseEnter={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.borderColor = "#AEAEAE";
                                        target.style.color = "#AEAEAE";
                                    }}
                                    onMouseLeave={(event) => {
                                        const target = event.target as HTMLElement;
                                        target.style.borderColor = "white";
                                        target.style.color = "white";
                                    }}
                                >
                                    Contact us
                                </ModalMakeOrderButton>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "static",
                            bottom: "0px",
                            width: "100%",
                            textAlign: "center",
                            fontFamily: "Abhaya Libre",
                            marginTop: "00px",
                            color: "white"
                        }}
                    >
                        © Triple Crown Trading 2024
                    </div>
                </div>
            </div>
        </>
    );
};
